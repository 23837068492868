<template lang="pug">
ThemesHeading(:color="theme.color")
  om-heading(h4) {{ $t('templateThemes.details.title') }}
  .row.brand-flex-reverse-column-mobile(:ref="paletteRef")
    .brand-themes-details-templates.row.col-12(:class="{ 'col-lg-8': colorable }")
      .col-12.col-md-6.mb-5.ssr(
        v-for="template in templates"
        :key="template._id"
        :class="{ 'col-lg-4 col-xxxl-4': !colorable }"
      )
        template-frame.cursor-pointer(
          @observable="addObservable($event.$el)"
          @inited="updateDimensions"
          :dimensions="boxDimensions"
          :template="template"
          :color="selectedColor"
          @contentLoaded="onContentLoaded"
          @click="openPreview(template._id)"
          :themeKit="theme.themeKit ? theme.themeKit : undefined"
        )
          template(#fallback)
            template-box.cursor-pointer(
              :template="template"
              @click="openPreview(template._id)"
              :color="selectedColor"
            )
    .brand-themes-details-colors.col-12.col-lg-4.mb-6(v-if="colorable")
      .brand-themes-details-colors-inner
        .brand-themes-details-color-label(v-html="$t('templateThemes.details.color')")
        om-color-palette(@selected="selectedColor = $event")
        .brand-themes-details-custom-label {{ $t('templateThemes.details.ownColor') }}
        om-color-picker(v-model="selectedColor")
  template-preview
  new-campaign
</template>
<script>
  import ThemesHeading from '@/components/Template/Themes/Heading.vue';
  import paletteMixin from '@/mixins/palette';
  import previewParentMixin from '@/mixins/previewParent';
  import ssrMixin from '@/mixins/ssr';
  import TemplatePreview from '@/components/Modals/TemplatePreview';
  import NewCampaign from '@/components/Modals/NewCampaign.vue';
  import GET_THEME_TEMPLATES from '@/graphql/GetThemeTemplates.gql';
  import TemplateFrame from '@/components/Template/TemplateFrame.vue';
  import TemplateBox from '@/components/Template/Themes/TemplateBox.vue';
  import observableCollectionMixin from '@/mixins/observableCollection';
  import tinycolor from 'tinycolor2';
  import GET_CUSTOM_THEMES from '@/graphql/GetCustomThemes.gql';

  export default {
    components: {
      ThemesHeading,
      TemplateBox,
      TemplatePreview,
      NewCampaign,
      TemplateFrame,
    },

    mixins: [paletteMixin, previewParentMixin, ssrMixin, observableCollectionMixin],

    data() {
      return {
        theme: {},
        selectedColor: '',
        templates: [],
        baseCssVersion: Date.now(),
      };
    },

    computed: {
      themeName() {
        return this.$route.params.themeName;
      },
      colorable() {
        return false;
      },
    },

    watch: {
      selectedColor(v) {
        this.updatePalette(v);
      },
    },

    async created() {
      const theme = await this.getTheme();

      this.theme = theme;
      const mainColor = this.getColor();

      this.selectedColor = tinycolor(mainColor).toString('hex').toUpperCase();

      const templates = await this.getTemplates();
      this.templates = templates;
    },

    mounted() {
      this.updatePalette(this.selectedColor);
    },

    methods: {
      async getTheme() {
        const {
          data: { themes },
        } = await this.$apollo.query({
          query: GET_CUSTOM_THEMES,
        });
        const { base = [] } = themes || {};
        const theme = base.find(({ name }) => name === this.themeName);
        return theme;
      },

      async getTemplates() {
        if (this.theme.templates) {
          return (this.theme || {}).templates || [];
        }

        const {
          data: { templates },
        } = await this.$apollo.query({
          query: GET_THEME_TEMPLATES,
          variables: {
            theme: this.theme.name,
            locale: this.$i18n.locale,
          },
        });
        return templates;
      },
      openPreview(templateId) {
        const previewParams = {
          templateId,
          themeKit: this.theme?.themeKit ? this.theme.themeKit : undefined,
        };
        this.$modal.show('template-preview', previewParams);
      },
      getColor() {
        return this.theme?.themeKit?.colors?.mainColor || this.theme.color;
      },
    },
  };
</script>

<style lang="sass">
  .brand-flex-reverse-column-mobile
    @media screen and (max-width: 991px)
      flex-direction: column-reverse
  .brand-row
    margin-left: -18px !important
    margin-right: -18px !important
  .brand-col
    padding-left: 18px !important
    padding-right: 18px !important
  .brand-themes-details
    .heading-4
      margin-top: 3.625rem
      margin-bottom: 2.1875rem

    &-templates
      .brand-themes-template-box
        // margin-bottom: 2rem

    &-colors
      flex: 1
      max-width: 27rem
      font-size: 0.9375rem
      line-height: 1.125rem
      &-inner
        position: sticky
        top: 2rem
      @media screen and (min-width: 992px)
        padding-left: 4.6875rem !important
        padding-right: 3.3125rem !important

    &-color-label
      font-weight: 500
      font-size: 1.25rem
      line-height: 1.4375rem

    &-custom-label
      margin-bottom: 0.6875rem
</style>
